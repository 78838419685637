<!--
 * @Author: 卢均锐morye
 * @Date: 2020-04-14 09:23:34
 * @LastEditTime: 2020-04-28 17:29:15
 -->
<template>
  <el-dialog :title="title" center v-bind="$attrs" v-on="$listeners" class="loser" width="45%">
    <div class="losetitle">
      <strong>{{ currentRow.name }}</strong>
      <div>
        <span>{{ currentRow.jobName }} {{ currentRow.phoneNumber }} {{ currentRow.mailbox }}</span>
      </div>
    </div>
    <el-form ref="form" :rules="rules" :model="form" label-width="100px">
      <p class="border-line"></p>
      <div v-if="!reject">
        <el-form-item prop="status" label="移库类型">
          <el-select v-model="form.status" placeholder="请选择移库类型" @change="_changeStatus">
            <el-option
              v-for="item in classifyListNew"
              :key="item.classifyId"
              :label="item.classifyName"
              :value="item.status"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="note" label="移库原因" v-if="form.status != ''">
          <el-select v-model="form.note" placeholder="请选择移库原因">
            <el-option
              v-for="item in noteList"
              :key="item.id"
              :label="item.tagName"
              :value="item.tagName"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="其他原因" prop="desc" v-if="form.note == '其他'">
          <el-input
            type="textarea"
            placeholder="请输入1-30字符"
            v-model="form.desc"
            maxlength="30"
            show-word-limit
          ></el-input>
        </el-form-item>
      </div>
      <el-form-item label="拒绝原因" prop="note" v-else>
        <el-input
          type="textarea"
          placeholder="请输入1-30字符"
          v-model="form.note"
          maxlength="30"
          show-word-limit
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="buttonDiv">
      <el-button @click="$emit('update:visible', false)">取 消</el-button>
      <el-button type="primary" @click="_move">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { recruitTalentMove } from '@/api/recruitment_allocation.js';
export default {
  props: ['title', 'currentRow', 'classifyListNew', 'reject'],
  data() {
    return {
      form: {
        resumeId: this.currentRow.id ? this.currentRow.id : this.currentRow.recruitId,
        reasonId: '',
        status: this.reject ? '8' : '',
        note: '',
        desc: ''
      },
      noteList: [],
      rules: {
        status: [
          { required: true, message: '请选择移库类型', trigger: 'change' }
        ],
        note: [
          { required: true, message: '请选择移库原因', trigger: 'change' }
        ],
        desc: [{ required: true, message: '请输入原因', trigger: 'blur' }]
      }
    };
  },
  methods: {
    _changeStatus(val) {
      this.form.note = '';
      this.form.desc = '';
      for (const key in this.classifyListNew) {
        if (this.classifyListNew[key].status == val) {
          this.noteList = this.classifyListNew[key].list;
        }
      }
      this.$forceUpdate();
    },
    _move() {
      const _obj = { ...this.form };

      let _statusName = '';

      if (this.reject) {
        _statusName = '已拒绝';
        _obj.reasonId = 0;
      } else {
        for (const keys in this.noteList) {
          if (this.noteList[keys].tagName == _obj.note) {
            _obj.reasonId = this.noteList[keys].id;
          }
        }
        for (const key in this.classifyListNew) {
          if (this.classifyListNew[key].status == this.form.status) {
            _statusName = this.classifyListNew[key].classifyName;
          }
        }
        if (_obj.note == '其他') {
          if (this.form.desc == '') {
            this.$message.error('请输入原因');
            return false;
          }
          _obj.note = this.form.desc;
        }
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$confirm(
            `您确认要将${this.currentRow.name}移到${_statusName}吗?`,
            '提示',
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }
          )
            .then(() => {
              recruitTalentMove(_obj).then((res) => {
                if (res.code == 0) {
                  this.$emit('updateList');
                  this.$emit('update:visible', false);
                  this.$message.success('移动成功');
                }
              });
            })
            .catch(() => {});
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.loser {
  .losetitle {
    margin: 0 20px;
    padding-bottom: 20px;
    // border-bottom: 1px dashed #ccc;
    strong {
      font-size: 16px;
      color: #2b3624;
      font-family:Heiti SC,"黑体-简";
      font-weight: normal;
    }
    div {
      margin-top: 12px;
      line-height: 20px;
      font-size: 14px;
      color: #777e8c;
    }
  }
  .border-line {
    border: 1px dashed #eaedf1;
    width: 93%;
    margin: 0 20px 20px;
    height: 1px;
    background-image: linear-gradient(
      to right,
      #ccc 0%,
      #ccc 50%,
      transparent 50%
    );
    background-size: 10px 1px;
    background-repeat: repeat-x;
  }
  .loseType,
  .reason {
    margin-left: 20px;
    .el-radio-group {
      margin: 20px 0;
    }
  }
  .reason {
    .el-radio {
      min-width: 16%;
      height: 25px;
    }
  }
  .buttonDiv {
    text-align: center;
    margin-top: 20px;
  }
}
</style>
