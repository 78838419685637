<!--
 * @Author: 卢均锐morye
 * @Date: 2020-04-11 14:44:24
 * @LastEditTime: 2020-04-30 17:51:19
 -->
<template>
	<div>
		<el-form :model="searchForm" inline ref="form" class="searchForm" size="small">
			<el-form-item label="性别">
				<el-select style="width: 160px" v-model="searchForm.sex" placeholder="选择性别" size="small" clearable>
					<el-option v-for="(item, i) in sexList" :key="i" :value="item.dictId" :label="item.dictName"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="来源渠道">
				<el-select style="width: 160px" v-model="searchForm.sources" placeholder="选择渠道" size="small" clearable>
					<el-option v-for="(item, i) in sourcesList" :key="i" :value="item.dictId" :label="item.dictName"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="应聘岗位">
				<el-select placeholder="请选择岗位" style="width: 160px" filterable size="small" clearable v-model="searchForm.jobId">
					<el-option v-for="item in jobList" :key="item.id" :label="item.jobOrOfficeName" :value="item.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="工作年限">
				<el-select style="width: 160px" v-model="searchForm.yearsWorking" placeholder="选择年限" size="small" clearable>
					<el-option v-for="(item, i) in experienceList" :key="i" :value="item.dictId" :label="item.dictName"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="最高学历">
				<el-select style="width: 160px" v-model="searchForm.highestEducation" placeholder="选择最高学历" size="small" clearable>
					<el-option v-for="(item, i) in highestEducationList" :key="i" :value="item.dictId" :label="item.dictName"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="创建时间">
				<el-date-picker
					v-model="searchForm.createTimeBefore"
					size="small"
					type="date"
					value-format="yyyy-MM-dd [00:00:00]"
					format="yyyy-MM-dd"
					clearable
				></el-date-picker>
				<span style="margin: 0 5px">-</span>
				<el-date-picker
					v-model="searchForm.createTimeEnd"
					size="small"
					type="date"
					value-format="yyyy-MM-dd [23:59:59]"
					format="yyyy-MM-dd"
					clearable
				></el-date-picker>
			</el-form-item>
			<el-form-item label="入职时间" style="margin-right: 5px">
				<el-date-picker
					v-model="searchForm.entryTimeBefore"
					size="small"
					type="date"
					value-format="yyyy-MM-dd [00:00:00]"
					format="yyyy-MM-dd"
					clearable
				></el-date-picker>
				<span style="margin: 0 5px">-</span>
				<el-date-picker
					v-model="searchForm.entryTimeEnd"
					size="small"
					type="date"
					value-format="yyyy-MM-dd [23:59:59]"
					format="yyyy-MM-dd"
					clearable
				></el-date-picker>
			</el-form-item>
			<el-form-item label-width="5px" label=" " style="margin-right: 0; transform: translateY(-5px)">
				<el-button type="primary" size="small" @click="_getList(1)" icon="el-icon-search">查询</el-button>
			</el-form-item>
		</el-form>
		<el-table :data="list" stripe>
			<el-table-column type="index" align="center" label="序号" width="50" fixed></el-table-column>
			<el-table-column
				v-for="(col, i) in columns"
				:prop="col.prop"
				:key="i"
				:align="col.prop == 'basic' ? 'left' : 'center'"
				:label="col.label"
				:width="col.prop == 'basic' ? 300 : 200"
				show-overflow-tooltip
			>
				<template v-slot="{ row }" v-if="col.prop == 'basic'">
					<p class="moryeBasic">
						{{ row.name }} <i :style="{ color: row.sex == '1' ? '#498DF0' : '#FF7371' }">{{ row.sex == '1' ? '♂' : '♀' }}</i>
						{{ row.yearsWorking }}·{{ row.highestEducationName }}
					</p>
					<p class="moryeBasic">{{ row.phoneNumber }}/{{ row.mailbox }}</p>
				</template>
				<template v-slot="{ row }" v-else-if="col.prop == 'school'">{{ row.graduatedSchool }}/{{ row.industry }}</template>
				<template v-slot="{ row }" v-else-if="col.prop == 'sourcesName'">{{
					sourcesList.find((val) => val.dictId == row.sources)['dictName']
				}}</template>
				<template v-slot="{ row }" v-else-if="col.prop == 'createTime'">{{ row.createTime && row.createTime.slice(0, 10) }}</template>
			</el-table-column>
			<el-table-column align="center" label="操作" fixed="right">
				<template v-slot="{ row }">
					<el-dropdown>
						<el-button type="text"><img src="../../../../assets/button/操作图标@3x.png" width="16px" /></el-button>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item
								v-for="(item, i) in commands"
								@click.native.stop="_handleRowClick(row, item)"
								:command="item"
								:key="i"
								>{{ item.label }}</el-dropdown-item
							>
							<!-- v-show="showItem(item)" -->
						</el-dropdown-menu>
					</el-dropdown>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination
			@size-change="_handleSizeChange"
			@current-change="_handleCurrentChange"
			:current-page="searchForm.pageNo"
			:page-sizes="[20, 40, 80, 100]"
			:page-size="searchForm.pageSize"
			style="float: right; margin-top: 10px"
			layout="total, sizes, prev, pager, next, jumper"
			:total="searchForm.totalNum"
		></el-pagination>
	</div>
</template>
<script>
import { noEntryList, recruitTalentMove } from '@/api/recruitment_allocation';
export default {
	props: ['jobArr'],
	data() {
		return {
			dateRange: '',
			dateRange2: '',
			sourcesList: [],
			sexList: [],
			experienceList: [],
			highestEducationList: [],
			list: [],
			jobList: this.jobArr,
			searchForm: {
				sex: '',
				sources: '',
				status: '',
				jobId: '',
				yearsWorking: '',
				highestEducation: '',
				createTimeBefore: '',
				createTimeEnd: '',
				entryTimeBefore: '',
				entryTimeEnd: '',
				keyword: '',
				pageNo: 1,
				pageSize: 20,
				totalNum: 0
			},
			columns: [
				{ label: '入职时间', prop: 'enrollEntryTime' },
				{ label: '基本信息', prop: 'basic' },
				{ label: '毕业院校/专业', prop: 'school' },
				{ label: '应聘职位', prop: 'jobName' },
				{ label: '投递渠道', prop: 'sources' },
				{ label: '创建时间', prop: 'createTime' }
			],
			commands: [
				{ label: '入职', value: '1' },
				{ label: '移动', value: '2' },
				{ label: '简历详情', value: '3' },
				{ label: '拒绝', value: '4' }
			],
			currentRow: {}
		};
	},
	methods: {
		_handleSizeChange(val) {
			this.searchForm.pageSize = val;
			this._getList();
		},
		_handleCurrentChange(val) {
			this.searchForm.pageNo = val;
			this._getList();
		},
		_getList(page) {
			page ? (this.searchForm.pageNo = page) : '';
			noEntryList(this.searchForm).then((res) => {
				this.list = res.list;
				this.searchForm.totalNum = res.totalNum;
			});
		},
		_dateRangeChange(val) {
			if (val) {
				this.searchForm.createTimeBefore = val[0];
				this.searchForm.createTimeEnd = val[1];
			} else {
				this._clear();
			}
		},
		_clear() {
			this.dateRange = '';
			this.searchForm.createTimeBefore = '';
			this.searchForm.createTimeEnd = '';
		},
		_dateRangeChange2(val) {
			if (val) {
				this.searchForm.entryTimeBefore = val[0];
				this.searchForm.entryTimeEnd = val[1];
			} else {
				this._clear2();
			}
		},
		_clear2() {
			this.dateRange2 = '';
			this.searchForm.entryTimeBefore = '';
			this.searchForm.entryTimeEnd = '';
		},
		_handleRowClick(row, item) {
			if (item.value == 2) {
				this.$emit('showDialog', { detail: row, title: '移动简历' });
			} else if (item.value == 3) {
				this.$router.push({ name: 'ResumeInfo', params: { id: row.id } });
			} else if (item.value == 1) {
				if (Number(row.incumbencyNumber) + 1 > row.employeesNumber) {
					return this.$message.error('编制人数已满');
				}
				if (row.number == '' || row.number <= 0) {
					this.$confirm(`${row.jobName}岗位需求已满，是否继续入职?`, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						this.$router.push({ name: 'AdmissionInfo', query: { resumeid: row.id } });
					});
				} else {
					this.$router.push({ name: 'AdmissionInfo', query: { resumeid: row.id } });
				}
				// this.$router.push({name:"AdmissionApply",params:{name:row.name}})
			} else {
				const _obj = { resumeId: row.id, reasonId: 0, status: 8 };

				this.$confirm(`确认将${row.name}移动到已拒绝?`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				})
					.then(() => {
						recruitTalentMove(_obj).then((res) => {
							if (res.code === 0) {
								this.$emit('updateList');
								this.$message.success('移动成功');
							}
						});
					})
					.catch(() => {});
				// this.$emit('showDialog',{detail :row,title: '移动简历',rejects : '1'});
			}
		}
	},
	mounted() {
		const mapArr = [
			{ name: 'sourcesList', groupId: 'fromType' },
			{ name: 'sexList', groupId: 'sex' },
			{ name: 'experienceList', groupId: 'experience' },
			{ name: 'highestEducationList', groupId: 'highestEducation' }
		];

		mapArr.forEach((val) => {
			this[val.name] = this.$store.state.app.dict.filter((item) => item.groupId == val.groupId);
		});
		this._getList();
	},
	watch: {
		jobArr: {
			deep: true,
			immediate: true,
			handler(newVal) {
				this.jobList = newVal;
			}
		}
	}
};
</script>
<style lang="scss" scoped>
::v-deep.el-table th,
.el-table tr {
	background-color: #f0f8ff;
}
.moryeBasic {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
</style>
