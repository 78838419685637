<!--
 * @Author: 卢均锐morye
 * @Date: 2020-04-11 14:32:55
 * @LastEditTime: 2020-04-21 15:13:34
 -->
<template>
	<div class="app-container">
		<el-tabs v-model="activeName">
			<el-tab-pane :label="`待入职(${_returnNumber(5)})`" name="1" lazy>
				<NoEntry ref="child1" :jobArr="jobList" @showDialog="_getDetail"></NoEntry>
			</el-tab-pane>
			<el-tab-pane :label="`已入职(${_returnNumber(6)})`" name="2" lazy>
				<Entry ref="child2" :jobArr="jobList" @showDialog="_getDetail"></Entry>
			</el-tab-pane>
			<el-tab-pane :label="`已过期(${_returnNumber(7)})`" name="3" lazy>
				<Overdue ref="child3" :jobArr="jobList" @showDialog="_getDetail"></Overdue>
			</el-tab-pane>
			<el-tab-pane :label="`已拒绝(${_returnNumber(8)})`" name="4" lazy>
				<Reject ref="child4" :jobArr="jobList" @showDialog="_getDetail" @updateList="_updateList"></Reject>
			</el-tab-pane>
		</el-tabs>
		<!-- <div style="position: absolute;top: 20px;right: 30px;">
      <el-input
        v-model="keyword"
        style="width:auto;margin-right:10px"
        size="small"
        placeholder="请输入关键字搜索"
        clearable
      ></el-input>
    </div> -->
		<MoveDialog
			v-if="dialogStatus"
			:currentRow="detail"
			:visible.sync="dialogStatus"
			:title="titles"
			:reject="rejects"
			:classifyListNew="classifyList"
			@updateList="_updateList"
		></MoveDialog>
	</div>
</template>
<script>
import { getPositions } from '@/api/common.js';
import Entry from './page/list_entry';
import NoEntry from './page/list_noEntry';
import Overdue from './page/list_overdue';
import Reject from './page/list_reject';
import MoveDialog from '../brainbank/components/moveDialog';
import { classifyList, listNum } from '@/api/recruitment_allocation.js';
export default {
	components: { Entry, NoEntry, Overdue, Reject, MoveDialog },
	data() {
		return {
			dialogVisible: false,
			activeName: '1',
			keyword: '',
			loseForm: {},
			jobList: [],
			detail: {},
			classifyList: [],
			dialogStatus: false,
			titles: ''
		};
	},
	methods: {
		_getDetail(obj) {
			this.detail = obj.detail;
			this.titles = obj.title;
			this.rejects = obj.rejects || '';
			this.dialogStatus = true;
		},
		_getJobList() {
			getPositions({ type: 2, pageNo: 1, pageSize: 20000 }).then((res) => {
				this.jobList = res.list;
			});
		},
		_getClassifyList() {
			classifyList({}).then((res) => {
				this.classifyList = res;
			});
		},
		_getNum() {
			listNum({}).then((res) => {
				this.numList = res;
				this.$forceUpdate();
			});
		},
		_updateList() {
			this._getNum();
			this.$refs[`child${this.activeName}`] && this.$refs[`child${this.activeName}`]._getList();
		},
		_returnNumber(status) {
			if (this.numList && this.numList.length >= 1) {
				for (const key in this.numList) {
					if (this.numList[key].status == status) {
						return this.numList[key].count;
					}
				}
				return 0;
			}
			return 0;
		}
	},
	beforeRouteLeave(to, from, next) {
		sessionStorage.setItem(
			`${this.$route.name}Data`,
			JSON.stringify({
				searchForm: this.searchForm,
				activeName: this.activeName
			})
		);
		next();
	},
	watch: {
		activeName(val) {
			this._getNum();
			this.$refs[`child${val}`] && this.$refs[`child${val}`]._getList();
		}
	},
	mounted() {
		const params = JSON.parse(sessionStorage.getItem(`${this.$route.name}Data`));

		if (params && params.activeName) {
			this.activeName = params.activeName;
		}
		this._getNum();
		this._getClassifyList();
		this._getJobList();
	}
};
</script>
<style lang="scss" scoped>
::v-deep.el-form-item {
	vertical-align: initial;
}
::v-deep.el-form-item__label {
	font-weight: normal;
}
</style>
